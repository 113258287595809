<template>
  <div class="donations-wrap widget-wrapper">
    <div class="title-wrap d-flex align-center">
      <v-img max-width="30" :src="require('@/assets/donations.svg')" />
      <block-text
        :text="$t('donations.title')"
        class="ml-3"
        type="title"
        color="secondary"
      />
    </div>
    <div class="content-wrap mt-8 d-flex align-center">
      <div class="donated-sum d-flex align-center">
        <v-img
          class="mb-1"
          width="52"
          height="52"
          :src="require('@/assets/ttu-coin-donation.svg')"
        />
        <div class="ml-5 d-flex flex-column">
          <block-text
            :text="$t('donations.totalDonated')"
            type="body-2"
            color="secondary"
          />
          <block-text
            v-if="donations && donations.ougoingDonationsSum"
            :text="donations.ougoingDonationsSum.toFixed(2)"
            type="title"
            color="secondary"
          />
        </div>
      </div>
      <v-divider vertical class="mx-8"></v-divider>
      <div class="received-sum d-flex flex-column">
        <block-text
          :text="$t('donations.totalReceived')"
          type="body-2"
          color="secondary"
        />
        <block-text
          v-if="donations && donations.incomeDonationsSum"
          :text="donations.incomeDonationsSum.toFixed(2)"
          type="title"
          color="primary"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      donations: "auth/donations",
    }),
  },
};
</script>

<style lang="scss" scoped>
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .donations-wrap {
    box-shadow: $box-shadow-dark;
    border-radius: $radius-md;
  }
}
</style>
